.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.skills-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px;
}
.skills-title {
  margin: 10px 20px 30px;
  text-align: center;
  width: 50%;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}
.skills-icons {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 70%;
}
.skills-icon {
  /* font-size: 60px; */
  width: 90px;
  margin: 20px;
}
@media screen and (max-width: 500px) {
  .skills-wrapper {
    margin: 75px 5% 10px;
  }
}
