.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  min-height: 100vh;
  background-image: url("../img/nyc02web.jpg");
  background-position: center;
  background-size: cover;
  color: white;
}
.contact a {
  color: white;
}
.contact-wrapper {
  height: 80vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 85px;
  width: 100%;
}
.contact-title {
  /* text-align: center; */
  /* white-space: nowrap; */
  text-align: center;
  width: 50%;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  /* width: 50%; */
}
.contact-text {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.contact-title,
.contact-text,
.contact-icon,
.contact-mail,
.contact-resume {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 1));
}
.contact-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 350px;
  font-size: 50px;
  margin-top: 40px;
}
.contact-link {
  color: white;

  margin: 5px 10px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
}
.contact-icon {
  width: 50px;
}
.contact-mail {
  width: 50px;
}
.contact-resume {
  width: 60px;
}
@media screen and (max-width: 500px) {
  .contact-links {
    width: 80%;
  }
  .contact-title {
    width: 90%;
  }
}
