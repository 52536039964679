.master {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
}

.master-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  text-align: center;
  padding: 30px;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 1));
}
.master-title-container > h1 {
  height: 50px;
  border-radius: 40%;
  font-size: 50px;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 1));
}

.master-title-container > h3 {
  height: 30px;
  font-size: 20px;
  border-radius: 40%;
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 1));
}
.master-loading {
  position: absolute;
  z-index: 90;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s linear, visibility 0.5s linear;
}

.master-loading.hide {
  opacity: 0;
  visibility: hidden;
}

.master-bg-img {
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: contain;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media screen and (max-width: 350px) {
  .master-title-container > h1 {
    margin-bottom: 65px;
  }
  .master-title-container {
    height: 300px;

    /* padding: 5px; */
  }
}
