@import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500;600;700&display=swap");
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.8);
}

::-webkit-scrollbar-thumb {
  background-color: #00a6ff;
  border-radius: 5px;
  border: 2px solid #0c61e8;
}

.App {
  font-family: "Gothic A1", sans-serif;
}
