header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100vw;
  user-select: none;
  height: 80px;
  color: white;
  z-index: 1;
  top: 0;
  left: 0;
}
.logo-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  background: black;
}
.logo {
  width: 150px;
  cursor: pointer;
  font-size: 30px;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 20px;
}

nav {
  width: 325px;
  transition: none;
  right: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.nav-link {
  outline: none;
  padding: 2px 10px;
  border-radius: 15px;
  border: 2px solid transparent;
  font-size: 18px;
  font-weight: 500;
}

.active-logo {
  border: none;
}

.nav-icon {
  display: none;
}

.logo:hover,
.nav-link:hover,
.active,
.active-logo {
  color: #00a6ff;
}

.active,
.nav-icon {
  border: 2px solid#00a6ff;
}

@media screen and (max-width: 500px) {
  .nav-icon > div {
    display: block;
    background-color: #00a6ff;
    border-radius: 3px;
    content: "";
    height: 2px;
    margin: 5px 0;
    transition: transform 0.3s ease-in-out;
  }

  .nav-icon-parent {
    width: 20%;
  }

  .nav-icon {
    display: block;
    height: 34px;
    width: 44px;
    border-radius: 10px;
    padding: 2px 5px 2px 5px;
    cursor: pointer;
  }

  header {
    flex-direction: row;
    justify-content: flex-start;
    height: 70px;
  }
  .logo {
    width: 80%;
    cursor: pointer;
    font-size: 30px;
    font-weight: 500;
    white-space: nowrap;
    margin: 15px 10px 15px 20px;
  }

  .logo-parent {
    flex-wrap: wrap;
  }

  nav {
    visibility: collapse;
    transform: translateY(-100%);
    top: 80px;
    right: 0px;
    margin: 0;
    width: 100%;
    padding: 4px 40px 8px;
    background-color: black;
    z-index: -1;
  }

  .nav-link {
    display: block;
  }

  nav.transition {
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  nav.visible {
    visibility: visible;
    transform: translateY(0);
  }

  .nav-icon.visible > :first-child {
    transform: translateY(5px) rotate(45deg);
  }

  .nav-icon.visible > :last-child {
    transform: translateY(-5px) rotate(-45deg);
  }

  .nav-icon.visible > :nth-child(2) {
    transform: scale(0);
  }

  /* the below code is for fading in the drop down menu */

  /* header {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 85px;
  }

  .logo-parent {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    width: 80%;
  }

  .nav-icon-parent {
    width: 20%;
  }

  .nav-link {
    display: block;
  }

  nav {
    visibility: collapse;
    opacity: 0;
    position: absolute;
    top: 84px;
    left: -1px;
    margin: 0;
    width: 101%;
    padding: 3px 40px;
  }

  nav.transition {
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  nav.visible {
    visibility: visible;
    opacity: 1;
  }

  .nav-icon {
    height: 38px;
    width: 44px;
    border: 4px solid #00a5ff;
    border-radius: 10px;
    padding: 2px 5px 2px 5px;
    cursor: pointer;
  }

  .nav-icon > div {
    display: block;
    background-color: #00a5ff;
    border-radius: 3px;
    content: "";
    height: 2px;
    margin: 5px 0;
    transition: transform 0.2s ease-in-out;
  }

  .nav-icon.visible > :first-child {
    transform: translateY(5px) rotate(45deg);
  }

  .nav-icon.visible > :last-child {
    transform: translateY(-5px) rotate(-45deg);
  }

  .nav-icon.visible > :nth-child(2) {
    transform: scale(0);
  } */
}
