.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: collapse;
  background-color: white;
  filter: drop-shadow(0 0 5px gray);
  border-radius: 20px;
  padding: 10px 30px;
  margin: auto;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  width: 60%;
}

.modal-text {
  white-space: pre-wrap;
  margin: 20px;
}

.modal-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: collapse;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  overflow-y: auto;
}

.modal > h2 {
  margin-top: 0;
  margin-bottom: 8px;
}

.modal > img {
  width: 60%;
  object-fit: contain;
  object-fit: cover;
  margin: 10px;
  filter: drop-shadow(0px 0px 10px gray);
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 350px;
}

.modal-button {
  height: 40px;
  padding: 5px 10px;
  margin: 10px;
  font-size: 20px;
  white-space: nowrap;
  background-image: linear-gradient(to bottom left, #00a6ff, #0085cc);
  color: white;
  border-radius: 15px;
  border: none;
  filter: drop-shadow(0px 0px 2px gray);
  border: none;
  outline: none;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
  opacity: 1;
}

/* .modal-button::before {
  border-radius: inherit;
  background-image: linear-gradient(to bottom left, #73cbfa, #0083c9);
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.1s ease-in-out;
}

.modal-button:active:before {
  opacity: 1;
} */

.modal-button:active {
  transform: scale(0.95);
}

.modal-close {
  align-self: flex-end;
  display: flex;
  align-items: center;
  font-size: 50px;
  height: 50px;
  color: #0296e6;
  background-color: transparent;
  border: none;
  outline: none;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
}

.modal-close:active {
  transform: scale(0.9);
}

.modal-parent.visible {
  visibility: visible;
  opacity: 1;
}

.modal.visible {
  visibility: visible;
  opacity: 1;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

@media screen and (max-width: 500px) {
  .modal > h2 {
    font-size: 18px;
  }
  .modal {
    padding: 10px 10px;
    width: 90%;
  }

  .modal > img {
    width: 95%;
    object-fit: contain;
    object-fit: cover;
    margin: 10px;
    filter: drop-shadow(0px 0px 10px gray);
  }

  .modal-buttons {
    width: 250px;
  }

  .modal-button {
    height: 25px;
    padding: 2px 8px;
    margin: 5px;
    font-size: 15px;
  }
}

@media screen and (min-width: 500px) and (max-width: 800px) {
  .modal > h2 {
    font-size: 18px;
  }

  .modal {
    padding: 10px 10px;
    width: 90%;
  }

  .modal > img {
    width: 95%;
    object-fit: contain;
    object-fit: cover;
    margin: 10px;
    filter: drop-shadow(0px 0px 10px gray);
  }

  .modal-buttons {
    width: 250px;
  }

  .modal-button {
    height: 25px;
    padding: 2px 8px;
    margin: 5px;
    font-size: 15px;
  }
}
