.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}
.about-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 20px 10px;
  margin: 65px;
  width: 100%;
}
.about-title {
  text-align: center;
  width: 50%;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 760px;
}
.about-img {
  width: 300px;
  object-fit: cover;
  object-fit: contain;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 2px gray);
}

.about-text {
  font-size: 18px;
  font-weight: 400;
  max-width: 100%;
  margin-left: 60px;
}
@media screen and (max-width: 800px) {
  .about-title {
    width: 50%;
    margin-bottom: 20px;
  }

  .about-container {
    flex-direction: column;
    width: 95%;
  }
  .about-img {
    width: 45%;
  }
  .about-text {
    width: 90%;
    margin: 20px 10px;
  }
}

@media screen and (max-width: 500px) {
  .about-img {
    width: 200px;
  }
  .about-container {
    flex-direction: column;
  }
}
